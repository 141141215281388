import { toTheme } from '@theme-ui/typography'
import typoTheme from 'typography-theme-lincoln'
import merge from 'deepmerge'
import { Theme } from 'theme-ui'

const _colors = {
  text: '#EBEFE7',
  background: '#2F4745',
  muted: '#3E5151',
  highlight: '#BDF5C6',
  primary: '#22343A',
  secondary: '#41625F',
  accent: '#69FF00'
}


const theme: Theme = merge(toTheme(typoTheme), {

  colors: {
    ..._colors
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
    }
  },
  cards: {
    primary: {
      padding: `0.5em`,
      borderRadius: 8,
      background: _colors.primary,
      boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.05)",
    },
  },
  breakpoints: ['640px', '768px', '1024px', '1280px'],
  my: {
    header: {
      maxWidth: 1280
    },
    footer: {
      maxWidth: 1280
    }
  }
})

export default theme;